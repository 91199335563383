
import { Component, Prop, Vue } from "vue-property-decorator";
import System from "@/models/System";
import Landing from "@/models/Landing";
import { uuid } from "vue-uuid";

@Component
export default class LandingDrawer extends Vue {
  @Prop() landing!: Landing;
  @Prop() create!: boolean;
  @Prop() show!: boolean;
  @Prop() loading!: boolean;
  @Prop() title!: string;
  @Prop() system!: System;

  rules = {
    name: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 3,
        message: "Debe contener al menos 3 caracteres.",
      },
    ],
    code: [
      {
        required: true,
        message: "El campo es obligatorio.",
        trigger: "blur",
      },
      {
        min: 5,
        message: "Debe contener al menos 5 caracteres.",
      },
    ],
  };

  keyRandom() {
    this.landing.code = uuid.v4().toString();
  }

  createLanding() {
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) return false;
      this.$emit("create");
    });
  }

  updateLanding() {
    (this.$refs["form"] as HTMLFormElement).validate((valid: boolean) => {
      if (!valid) return false;
      this.$emit("update");
    });
  }

  closeDrawer(cancel?: boolean) {
    (this.$refs["form"] as HTMLFormElement).clearValidate();
    this.$emit("closeDrawer", cancel);
  }

  get showDrawer() {
    return this.show;
  }
}
