import System from "@/models/System";

export default class Landing {
  id?: number;
  name!: string;
  code = "";
  html = "";
  active = true;
  system?: System;
  systemId!: number;
  createdAt?: Date;
  updatedAt?: Date;
}
